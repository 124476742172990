<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 기본정보 -->
          <c-card title="LBLBASEINFO" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 삭제 -->
                <c-btn 
                  v-if="editable && !disabled && isOld" 
                  label="LBLREMOVE" 
                  icon="delete"
                  @btnClicked="deleteData" />
                <!-- 완료 -->
                <c-btn
                  v-show="editable && !disabled && isOld"
                  :url="completeUrl"
                  :isSubmit="isComplete"
                  :param="budgetPlan"
                  mappingType="PUT"
                  label="LBLCOMPLETE"
                  icon="check"
                  @beforeAction="completeData"
                  @btnCallback="completeCallback" />
                <!-- 저장 -->
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="budgetPlan"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable"
                  name="plantCd" 
                  v-model="budgetPlan.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <!-- 예산년도 -->
                <c-datepicker
                  required
                  label="LBL0010320"
                  name="budgetYear"
                  type="year"
                  default="today"
                  v-model="budgetPlan.budgetYear"
                />
              </div>
              <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
                <!-- 총 예산금액 -->
                <c-text
                  :disabled="true"
                  suffix="원"
                  type="number"
                  :editable="editable"
                  label="LBL0010324"
                  name="budgetAllAmt"
                  v-model="budgetPlan.budgetAllAmt"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-12">
          <!-- 항목별 예산편성 -->
          <c-table
            ref="table"
            title="LBL0010325"
            tableId="usePlanItem"
            :columns="grid.columns"
            :data="budgetPlan.planItems"
            gridHeight="500px"
            :editable="editable&&!disabled"
            :columnSetting="false"
            :expandAll="false"
            :isFullScreen="false"
            :usePaging="false"
            :hideBottom="true"
            :filtering="false"
            @table-data-change="tableDataChange"
            rowKey="budgetYearPlanItemId"
            selection="multiple"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <!-- 추가 -->
                <c-btn v-if="editable && !disabled" label="LBLADD" icon="add" @btnClicked="addItem" />
                <!-- 제외 -->
                <c-btn v-if="editable && !disabled" label="LBLEXCEPT" icon="remove" @btnClicked="deleteItem" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'budget-year-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        budgetYearPlanId: '',
        plantCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      budgetPlan: {
        budgetYearPlanId: '',
        budgetAllAmt: 0,
        plantCd: '',
        budgetYear: '',
        budgetStatusCd: '',
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        planItems: [],
        deletePlanItems: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'budgetTypeName',
            field: 'budgetTypeName',
            // 항목
            label: 'LBLITEMS',
            align: 'left',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'budgetItemName',
            field: 'budgetItemName',
            // 항목
            label: 'LBLITEMS',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          { 
            required: true,
            name: 'budgetPlanAmt',
            field: 'budgetPlanAmt',
            // 예산 사용금액(원)
            label: 'LBL0010326',
            align: 'right',
            style: 'width: 150px',
            sortable: false,
            type: 'number'
          },
          {
            name: 'budgetDetails',
            field: 'budgetDetails',
            // 비고
            label: 'LBLREMARK',
            style: 'width: 350px',
            align: 'left',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'attach',
            field: 'attach',
            // 항목별 첨부
            label: 'LBL0010327',
            align: 'center',
            style: 'width: 250px',
            type: 'attach',
            taskClassCd: 'SAI_BUDGET_ITEM',
            keyText: 'budgetYearPlanItemId',
            sortable: false,
          },
        ],
      },
      mappingType: 'POST',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveUrl: 'transactionConfig.sai.budget.year.insert.url',
      listItemUrl: '',
      completeUrl: '',
      editable: true,
      isSave: false,
      isComplete: false,
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.budgetYearPlanId)
    },
    disabled() {
      return this.isOld && this.budgetPlan.budgetStatusCd === 'BS00000005'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.listItemUrl = selectConfig.sai.budget.item.list.url;
      this.checkUrl = selectConfig.sai.budget.year.check.url
      this.detailUrl = selectConfig.sai.budget.year.get.url
      this.insertUrl = transactionConfig.sai.budget.year.insert.url
      this.updateUrl = transactionConfig.sai.budget.year.update.url
      this.deleteUrl = transactionConfig.sai.budget.year.delete.url
      this.completeUrl = transactionConfig.sai.budget.year.complete.url

      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.budgetYearPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.budgetYearPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.budgetPlan = _result.data
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.getItems();
      }
    },
    getItems() {
      if (!this.budgetPlan.plantCd) {
        this.budgetPlan.planItems = [];
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010026', // 사업장을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.listItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.budgetPlan.plantCd,
        }
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.budgetPlan.planItems.push({
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: _item.saiBudgetItemId,
              budgetItemName: _item.budgetItemName,
              budgetContent: _item.budgetContent,
              budgetTypeName: _item.budgetTypeName,
              budgetPlanAmt: 0,
              budgetDetails: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          })
        },);
      }
    },
    tableDataChange(props, col) {
      this.budgetPlan.budgetAllAmt = 0;
      if (col.name === 'budgetPlanAmt') {
        if (props.row['budgetPlanAmt'] && props.row['budgetPlanAmt'] > 0) {
          this.$_.forEach(this.budgetPlan.planItems, _item => {
            if (_item.budgetPlanAmt && _item.budgetPlanAmt > 0) {
              this.budgetPlan.budgetAllAmt = Number(this.budgetPlan.budgetAllAmt) + Number(_item.budgetPlanAmt)
            } else {
              _item.budgetPlanAmt = 0
            }
          })
        } else {
          props.row['budgetPlanAmt'] = 0
        }
      }
    },
    saveData() {
      if (this.popupParam.budgetYearPlanId) {
        this.saveUrl = transactionConfig.sai.budget.year.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.budget.year.insert.url
        this.mappingType = 'POST';
        this.budgetPlan.budgetStatusCd = 'BS00000001'
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.grid.columns, this.budgetPlan.planItems)) {
            this.$http.url = this.checkUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: this.budgetPlan.plantCd,
              budgetYear: this.budgetPlan.budgetYear,
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
            };
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0010027',  // 동일한 년도의 예산편성이 있습니다.
                  type: 'warning', // success / info / warning / error
                });
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',  // 확인
                  message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.budgetPlan.regUserId = this.$store.getters.user.userId;
                    this.budgetPlan.chgUserId = this.$store.getters.user.userId;
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            },);
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.budgetYearPlanId = result.data
      this.getDetail();
    },
    completeData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGCOMPLETE', // 완료하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.budgetPlan.chgUserId = this.$store.getters.user.userId;
              this.budgetPlan.budgetStatusCd = 'BS00000005'
              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.budgetYearPlanId = result.data
      this.getDetail(this.popupParam.budgetYearPlanId, true);
    },
    /* eslint-disable no-unused-vars */ 
    addItem() {
      this.popupOptions.title = 'LBL0010328';   // 예산편성 항목 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.budgetPlan.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/budget/budgetItemPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMst;
    },
    closePopMst(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if ( this.$_.findIndex(this.budgetPlan.planItems, {saiBudgetItemId: _item.saiBudgetItemId}) === -1 ) {
            this.budgetPlan.planItems.push({
              budgetYearPlanId: this.popupParam.budgetYearPlanId,
              budgetYearPlanItemId: uid(),
              saiBudgetItemId: _item.saiBudgetItemId,
              budgetItemName: _item.budgetItemName,
              budgetContent: _item.budgetContent,
              budgetTypeName: _item.budgetTypeName,
              budgetPlanAmt: 0,
              budgetDetails: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        });
      }
    },
    deleteItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.budgetPlan.deletePlanItems) this.budgetPlan.deletePlanItems = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.budgetPlan.deletePlanItems, { budgetYearPlanItemId: item.budgetYearPlanItemId })) {
              this.budgetPlan.deletePlanItems.push(item)
          }
          this.budgetPlan.planItems = this.$_.reject(this.budgetPlan.planItems, item);
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.budgetYearPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
